.navigation {
    display: flex;
    flex-direction: column;
    width: 50vw;
    height: 100vh;
    border-right: 1px solid var(--atg-white);

    @media only screen and (min-device-width: 300px) and (max-device-width: 812px) {
        width: 100%;
        border-right: none;
    }

    .header {
        display: flex;
        width: 100%;
        height: 150px;
        border-bottom: 1px solid var(--atg-white);

        .connect {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30%;

            @media only screen and (min-device-width: 300px) and (max-device-width: 812px) {
                width: 40%;
            }

            .link {
                color: var(--atg-white);
                font-family: "Neue-Plak-Black", sans-serif;
                font-size: 32px;
                text-decoration: none;
                transition: color 0.25s ease-out;

                @media only screen and (min-device-width: 300px) and (max-device-width: 812px) {
                    font-size: 24px;
                }

                &:hover {
                    color: var(--atg-pink);
                }
            }
        }

        .logo {
            display: flex;
            align-items: center;
            width: 70%;
            border-right: 1px solid var(--atg-white);
            padding-left: 56px;

            @media only screen and (min-device-width: 300px) and (max-device-width: 812px) {
                width: 60%;
                justify-content: center;
                padding-left: 0;
            }

            .image {
                height: 32px;

                @media only screen and (min-device-width: 300px) and (max-device-width: 812px) {
                    height: 24px;
                    width: auto;
                }
            }
        }
    }

    .links {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100vw;
        height: 100%;
        padding: 160px 120px;

        @media only screen and (min-device-width: 300px) and (max-device-width: 812px) {
            padding: 120px 45px;
        }

        .item {
            display: flex;
            align-items: flex-end;
            font-family: "Neue-Plak-Black", sans-serif;

            .text {
                display: flex;
                align-items: flex-end;

                .link {
                    color: var(--atg-white);
                    font-size: 96px;
                    line-height: 60px;
                    text-decoration: none;
                    cursor: pointer;
                    transition: color 0.25s ease-out;

                    @media only screen and (min-device-width: 300px) and (max-device-width: 812px) {
                        font-size: 48px;
                        line-height: 38px;
                    }

                    &:hover {
                        color: var(--atg-pink);
                    }
                }
            }

            .number {
                display: flex;
                align-items: flex-end;
                margin-right: 68px;
                color: var(--atg-white);
                font-size: 32px;
                line-height: 24px;

                @media only screen and (min-device-width: 300px) and (max-device-width: 812px) {
                    margin-right: 32px;
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }
    }
}
