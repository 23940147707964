.slideshow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 136px 128px;
    overflow: hidden;

    @media only screen and (min-device-width: 300px) and (max-device-width: 812px) {
        display: none;
    }

    .display {
        position: relative;
        display: flex;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        border-radius: 128px;

        &:before {
            position: absolute;
            width: 100%;
            height: 100%;
            border: 1px solid var(--atg-white);
            border-radius: 128px;
            transform: translate(-16px, -16px);
            content: "";
        }

        .circle {
            position: absolute;
            top: 0;
            left: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 160px;
            height: 160px;
            border-radius: 50%;
            border: 1px solid var(--atg-white);
            background-color: var(--atg-black);
            z-index: 100;
            transform: translate(calc(-100% + 48px), -48px);

            .image {
                width: 50%;
            }
        }

        .container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            border: 1px solid var(--atg-white);
            border-radius: 128px;
            overflow: hidden;
            transform: translate(16px, 16px);
        }
    }
}
