@font-face {
    font-family: "Neue-Plak-Black";
    src: url("fonts/Neue-Plak-Black.ttf") format("truetype");
}

.app {
    display: flex;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    background-color: var(--atg-black);
    overflow: hidden;
}
